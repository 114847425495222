<!-- TODO(aes)
<head>
    = stylesheet_link_tag "vendor"
    = stylesheet_link_tag "application"

    = favicon_link_tag "favicon.png"
    = csrf_meta_tags

    %script{type: "text/javascript"}
      window.App = {userId: "#{current_user.try(:id)}", isDmsEnabled:#{user_is_dms_enabled?.to_json}}
</head>
-->

<template>
        <div>
                <GoogleTagManager />
                <slot />
        </div>
</template>

<script setup>
import { init } from "~/assets/js/call_out/custom.js"

import layoutStyle from "~/assets/styles/styles.scss?inline"
useHead({
        style: [layoutStyle]
})

onMounted(() => {
        init()
})
</script>
